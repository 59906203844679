// src/App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Generator from './features/helldivers2/components/Generator';  // Updated import path
import HomePage from './components/HomePage';
import OptOutBanner from './components/OptOutBanner';
import { Helmet } from 'react-helmet';

const Tracking: React.FC = () => {
  const location = useLocation();
  const [isOptedOut, setIsOptedOut] = useState<boolean>(false);

  useEffect(() => {
    const optOutCookie = document.cookie.split('; ').find(row => row.startsWith('ga-opt-out='));
    if (optOutCookie) {
      setIsOptedOut(optOutCookie.split('=')[1] === 'true');
    }
  }, []);

  useEffect(() => {
    if (window.gtag && !isOptedOut) {
      window.gtag('config', 'G-GS3WL1HNQR', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, isOptedOut]);

  return null;
};

const App: React.FC = () => {
  const [isOptedOut, setIsOptedOut] = useState<boolean>(false);

  const handleOptOut = () => {
    document.cookie = 'ga-opt-out=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    setIsOptedOut(true);
  };

  return (
    <Router>
      <Tracking />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>loadoutgenerator.net</title>
                <meta name="description" content="Generate random loadouts for Helldivers 2" />
              </Helmet>
              <HomePage />
            </>
          }
        />
        <Route
          path="/helldivers2"
          element={
            <>
              <Helmet>
                <title>loadoutgenerator.net - HELLDIVERS 2 Generator</title>
                <meta name="description" content="Generate random loadouts for Helldivers 2" />
              </Helmet>
              <Generator />
            </>
          }
        />
      </Routes>
      {!isOptedOut && <OptOutBanner onOptOut={handleOptOut} />}
    </Router>
  );
};

declare global {
  interface Window {
    gtag?: (
      command: 'config' | 'event',
      targetId: string,
      config?: { [key: string]: any }
    ) => void;
  }
}

export default App;
