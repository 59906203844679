import { useState } from 'react';
import {
  Helldivers2Loadout,
  PRIMARY_WEAPONS,
  SECONDARY_WEAPONS,
  GRENADES,
  BOOSTERS,
  Stratagem
} from '../types';

const STRATAGEMS: Stratagem[] = [
  {
    name: 'MG-43 Machine Gun',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Machine Gun.svg",
  },
  {
    name: 'APW-1 Anti-Materiel Rifle',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Anti-Materiel Rifle.svg",
  },
  {
    name: 'M-105 Stalwart',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Stalwart.svg",
  },
  {
    name: 'EAT-17 Expendable Anti-Tank',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Expendable Anti-Tank.svg",
  },
  {
    name: 'GR-8 Recoilless Rifle',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Recoilless Rifle.svg",
  },
  {
    name: 'FLAM-40 Flamethrower',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Flamethrower.svg",
  },
  {
    name: 'AC-8 Autocannon',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Autocannon.svg",
  },
  {
    name: 'MG-206 Heavy Machine Gun',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Heavy Machine Gun.svg",
  },
  {
    name: 'RL-77 Airburst Rocket Launcher',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Airburst Rocket Launcher.svg",
  },
  {
    name: 'RS-422 Railgun',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Railgun.svg",
  },
  {
    name: 'FAF-14 Spear',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/Spear.svg",
  },
  {
    name: 'StA-X3 W.A.S.P. Launcher',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Patriotic Administration Center/StA-X3 W.A.S.P. Launcher.svg",
  },
  {
    name: 'Orbital Gatling Barrage',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Gatling Barrage.svg",
  },
  {
    name: 'Orbital Airburst Strike',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Airburst Strike.svg",
  },
  {
    name: 'Orbital 120mm HE Barrage',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital 120MM HE Barrage.svg",
  },
  {
    name: 'Orbital 380mm HE Barrage',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital 380MM HE Barrage.svg",
  },
  {
    name: 'Orbital Walking Barrage',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Walking Barrage.svg",
  },
  {
    name: 'Orbital Laser',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Laser.svg",
  },
  {
    name: 'Orbital Napalm Barrage',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Napalm Barrage.svg",
  },
  {
    name: 'Orbital Railcannon Strike',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Orbital Cannons/Orbital Railcannon Strike.svg",
  },
  {
    name: 'Eagle Strafing Run',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Strafing Run.svg",
  },
  {
    name: 'Eagle Airstrike',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Airstrike.svg",
  },
  {
    name: 'Eagle Cluster Bomb',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Cluster Bomb.svg",
  },
  {
    name: 'Eagle Napalm Airstrike',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Napalm Airstrike.svg",
  },
  {
    name: 'LIFT-850 Jump Pack',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Jump Pack.svg",
  },
  {
    name: 'Eagle Smoke Strike',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle Smoke Strike.svg",
  },
  {
    name: 'Eagle 110mm Rocket Pods',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle 110MM Rocket Pods.svg",
  },
  {
    name: 'Eagle 500kg Bomb',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Hangar/Eagle 500KG Bomb.svg",
  },
  {
    name: 'Orbital Precision Strike',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Orbital Precision Strike.svg",
  },
  {
    name: 'Orbital Gas Strike',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Orbital Gas Strike.svg",
  },
  {
    name: 'Orbital EMS Strike',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Orbital EMS Strike.svg",
  },
  {
    name: 'Orbital Smoke Strike',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Orbital Smoke Strike.svg",
  },
  {
    name: 'E/MG-101 HMG Emplacement',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/HMG Emplacement.svg",
  },
  {
    name: 'FX-12 Shield Generator Relay',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Shield Generator Relay.svg",
  },
  {
    name: 'A/ARC-3 Tesla Tower',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Bridge/Tesla Tower.svg",
  },
  {
    name: 'MD-6 Anti-Personnel Minefield',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Anti-Personnel Minefield.svg",
  },
  {
    name: 'B-1 Supply Pack',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Supply Pack.svg",
  },
  {
    name: 'GL-21 Grenade Launcher',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Grenade Launcher.svg",
  },
  {
    name: 'LAS-98 Laser Cannon',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Laser Cannon.svg",
  },
  {
    name: 'MD-I4 Incendiary Mines',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Incendiary Mines.svg",
  },
  {
    name: 'AX/LAS-5 "Guard Dog" Rover',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Guard Dog Rover.svg",
  },
  {
    name: 'SH-20 Ballistic Shield Backpack',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Ballistic Shield Backpack.svg",
  },
  {
    name: 'ARC-3 Arc Thrower',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Arc Thrower.svg",
  },
  {
    name: 'MD-17 Anti-Tank Mines',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Anti-Tank Mines.svg",
  },
  {
    name: 'LAS-99 Quasar Cannon',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Quasar Cannon.svg",
  },
  {
    name: 'SH-32 Shield Generator Pack',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Engineering Bay/Shield Generator Pack.svg",
  },
  {
    name: 'A/MG-43 Machine Gun Sentry',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Machine Gun Sentry.svg",
  },
  {
    name: 'A/G-16 Gatling Sentry',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Gatling Sentry.svg",
  },
  {
    name: 'A/M-12 Mortar Sentry',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Mortar Sentry.svg",
  },
  {
    name: 'AX/AR-23 "Guard Dog"',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Guard Dog.svg",
  },
  {
    name: 'A/AC-8 Autocannon Sentry',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Autocannon Sentry.svg",
  },
  {
    name: 'A/MLS-4X Rocket Sentry',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Rocket Sentry.svg",
  },
  {
    name: 'A/M-23 EMS Mortar Sentry',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/EMS Mortar Sentry.svg",
  },
  {
    name: 'EXO-45 Patriot Exosuit',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Robotics Workshop/Patriot Exosuit.svg",
  },
  {
    name: 'TX-41 Sterilizer',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Chemical Agents/Sterilizer.svg",
  },
  {
    name: 'AX/TX-13 "Guard Dog" Dog Breath',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Chemical Agents/Guard Dog Breath.svg",
  },
  {
    name: 'SH-51 Directional Shield',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Urban Legends/Directional Shield.svg",
  },
  {
    name: 'E/AT-12 Anti-Tank Emplacement',
    icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Urban Legends/Anti-Tank Emplacement.svg",
  },
  {
    name: 'A/FLAM-40 Flame Sentry',
  icon: "assets/icons/Helldivers-2-Stratagems-icons-svg/Urban Legends/Flame Sentry.svg",
  }
]

export function useHelldivers2Generator() {
  const [loadout, setLoadout] = useState<Helldivers2Loadout | null>(null);

  const generateLoadout = () => {
    // Helper function to get random item from array
    const getRandomItem = <T>(array: readonly T[]): T => {
      return array[Math.floor(Math.random() * array.length)];
    };

    // Generate unique stratagems
    const usedStratagemNames = new Set<string>();
    const selectedStratagems: Stratagem[] = [];

    while (selectedStratagems.length < 4) {
      const stratagem = getRandomItem(STRATAGEMS);
      if (!usedStratagemNames.has(stratagem.name)) {
        usedStratagemNames.add(stratagem.name);
        selectedStratagems.push(stratagem);
      }
    }

    const newLoadout: Helldivers2Loadout = {
      primaryWeapon: getRandomItem(PRIMARY_WEAPONS),
      secondaryWeapon: getRandomItem(SECONDARY_WEAPONS),
      grenade: getRandomItem(GRENADES),
      stratagems: selectedStratagems,
      booster: getRandomItem(BOOSTERS)
    };

    setLoadout(newLoadout);
  };

  return {
    loadout,
    generateLoadout
  };
}

