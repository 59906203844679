// src/features/helldivers2/types/index.ts

export interface Stratagem {
  name: string;
  icon: string;
}

export interface Helldivers2Loadout {
  primaryWeapon: string;
  secondaryWeapon: string;
  grenade: string;
  stratagems: Stratagem[];
  booster: string;
}

export const PRIMARY_WEAPONS = [
  "AR-23 Liberator",
  "AR-23P Liberator Penetrator",
  "AR-23C Liberator Concussive",
  "StA-52 Assault Rifle",
  "AR-23A Liberator Carbine",
  "AR-61 Tenderizer",
  "BR-14 Adjudicator",
  "R-2124 Constitution",
  "R-63 Diligence",
  "R-63CS Diligence Counter Sniper",
  "PLAS-39 Accelerator Rifle",
  "MP-98 Knight",
  "StA-11 SMG",
  "SMG-32 Reprimand",
  "SMG-37 Defender",
  "SMG-72 Pummeler",
  "SG-8 Punisher",
  "SG-8S Slugger",
  "SG-20 Halt",
  "SG-451 Cookout",
  "SG-225 Breaker",
  "SG-225SP Breaker Spray and Pray",
  "SG-225IE Breaker Incendiary",
  "CB-9 Exploding Crossbow",
  "R-36 Eruptor",
  "SG-8P Punisher Plasma",
  "ARC-12 Blitzer",
  "LAS-5 Scythe",
  "LAS-16 Sickle",
  "PLAS-1 Scorcher",
  "PLAS-101 Purifier",
  "FLAM-66 Scorcher",
  "JAR-5 Dominator"
] as const;

export const SECONDARY_WEAPONS = [
  "P-2 Peacemaker",
  "P-19 Redeemer",
  "P-113 Verdict",
  "P-4 Senator",
  "CQC-19 Stun Lance",
  "CQC-30 Stun Baton",
  "P-11 Stim Pistol",
  "SG-22 Bushwhacker",
  "P-72 Crisper",
  "GP-31 Grenade Pistol",
  "LAS-7 Dagger",
  "PLAS-15 Loyalist"
] as const;

export const GRENADES = [
  "G-6 Frag",
  "G-12 High Explosive",
  "G-10 Incendiary",
  "G-16 Impact",
  "G-13 Incendiary Impact",
  "G-23 Stun",
  "G-4 Gas",
  "G-3 Smoke",
  "G-123 Thermite",
  "K-2 Throwing Knife"
] as const;

export const BOOSTERS = [
  "Hellpod Space Optimization",
  "Vitality Enhancement",
  "UAV Recon Booster",
  "Stamina Enhancement",
  "Muscle Enhancement",
  "Increased Reinforcement Budget",
  "Flexible Reinforcement Budget",
  "Localization Confusion"
] as const;
