// src/features/helldivers2/components/Generator/index.tsx
import React from "react";
import { Helmet } from "react-helmet";
import { useHelldivers2Generator } from "../../hooks/useHelldivers2Generator";
import { Stratagem } from "../../types";
import "./styles.css";

const Generator: React.FC = () => {
  const { loadout, generateLoadout } = useHelldivers2Generator();

  return (
    <div className="container">
      <Helmet>
        <title>HELLDIVERS 2 Loadout Generator</title>
        <meta
          name="description"
          content="Generate random loadouts for Helldivers 2."
        />
      </Helmet>

      <div className="container">
        <h1>HELLDIVERS 2 Random Loadout Generator</h1>
        <button className="button" onClick={generateLoadout}>
          Generate Loadout
        </button>
      </div>

      {loadout && (
        <div className="loadout">
          <div className="loadout-item">
            <h3>Primary Weapon</h3>
            <p>{loadout.primaryWeapon}</p>
          </div>
          <div className="loadout-item">
            <h3>Secondary Weapon</h3>
            <p>{loadout.secondaryWeapon}</p>
          </div>
          <div className="loadout-item">
            <h3>Grenade</h3>
            <p>{loadout.grenade}</p>
          </div>
          <div className="loadout-item">
            <h3>Stratagems</h3>
            {loadout.stratagems.map((stratagem: Stratagem, index: number) => (
              <div key={index}>
                <p>{stratagem.name}</p>
                <img src={stratagem.icon} alt={stratagem.name} />
              </div>
            ))}
          </div>
          <div className="loadout-item">
            <h3>Booster</h3>
            <p>{loadout.booster}</p>
          </div>
        </div>
      )}

      <footer className="footer">
        <p>
          <strong>Coming Soon:</strong> Loadout saving
        </p>
        <p>Updated 12/29/2024 - Fixed duplicate stratagem selection bug</p>
      </footer>
    </div>
  );
};

export default Generator;
