// src/components/OptOutBanner/index.tsx
import React, { useState } from 'react';
import './styles.css';

interface OptOutBannerProps {
  onOptOut: () => void;
}

const OptOutBanner: React.FC<OptOutBannerProps> = ({ onOptOut }) => {
  const [showBanner, setShowBanner] = useState(true);

  const handleOptOut = () => {
    document.cookie = 'ga-opt-out=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    onOptOut();
    setShowBanner(false);
  };

  const handleDismiss = () => {
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="opt-out-banner">
      <p>I use Google Analytics to understand and improve how you use my site. You can <button onClick={handleOptOut}>opt out</button> if you wish.</p>
      <button className="dismiss-button" onClick={handleDismiss}>X</button>
    </div>
  );
};

export default OptOutBanner;
